import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, HashRouter, Route, Routes } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import './scss/style.scss'
import CONTACT from './views/contactUs/contactUs'
import MetaWrapper from './MetaWrapper'
// import { PageLayout } from './components/login/PageLayout'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Terms = React.lazy(() => import('./views/termsConditions/termsConditions'))
const FAQ = React.lazy(() => import('./views/faq/faq'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const CreateBrand = React.lazy(() => import('./views/brands/CreateBrand'))
const SignupAccount = React.lazy(() => import('./views/signup/SignupAccount'))
const SignupBusiness = React.lazy(() => import('./views/signup/SignupBusiness'))
const SignupBrand = React.lazy(() => import('./views/signup/SignupBrand'))
const MarketplaceSignup = React.lazy(() => import('./views/signup/marketplaceSignup'))
const CropLogo = React.lazy(() => import('./views/brands/cropLogo'))
const EvcashCampaignsEdit = React.lazy(() => import('./views/evcashCampaigns/evcashCampaignsEdit'))
const EvcashCampaigns = React.lazy(() => import('./views/evcashCampaigns/evcashCampaignsList'))

class App extends Component {
  render() {
    return (
      <HelmetProvider>
        <Router>
          {/* <Suspense fallback={loading}> */}
          <MetaWrapper>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route
                exact
                path="/TermsAndConditions"
                name="Terms and conditions"
                element={<Terms />}
              />
              <Route exact path="/FAQ" name="FAQ" element={<FAQ />} />
              <Route exact path="/contactUs" name="Contact us" element={<CONTACT />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route exact path="/createBrand" name="Brand Details" element={<CreateBrand />} />
              <Route
                exact
                path="/signupAccount"
                name="SignUp Account"
                element={<SignupAccount />}
              />
              <Route
                exact
                path="/signupBusiness"
                name="SignUp Business"
                element={<SignupBusiness />}
              />
              <Route exact path="/signupBrand" name="SignUp Brand" element={<SignupBrand />} />
              <Route exact path="/join" name="Marketplace Signup" element={<MarketplaceSignup />} />
              <Route exact path="/cropLogo" name="Brand Details" element={<CropLogo />} />
              {/* <Route
            exact
            path="/evcashCampaignEdit"
            name="Campaign Edit"
            element={<EvcashCampaignsEdit />}
            render={(props) => <DefaultLayout {...props} />}
          />
                <Route
            exact
            path="/evcashCampaigns"
            name="Campaigns"
            element={<EvcashCampaigns />}
            render={(props) => <DefaultLayout {...props} />}
          /> */}
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </MetaWrapper>
          {/* </Suspense> */}
        </Router>
      </HelmetProvider>
    )
  }
}

// const MainContent = () => {
//   const isAuthenticated = true

//   return <>{isAuthenticated ? <App1 /> : <></>}</>
// }

// export default function App() {
//   //return <App1 />
//   return (
//     <PageLayout>
//       <MainContent />
//     </PageLayout>
//   )
// }

export default App
