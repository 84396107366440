import React from 'react'
const BrandSettings = React.lazy(() => import('./views/brandSettings/BrandSettings'))
const Reports = React.lazy(() => import('./views/reports/Reports'))
const ApiManagement = React.lazy(() => import('./views/apiManagement/ApiManagement'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const CropLogo = React.lazy(() => import('./views/brands/cropLogo'))
const EvcashCampaignsEdit = React.lazy(() =>
  import('./views/evcashCampaigns/evcashCampaignsShortEdit'),
)
const EvcashCampaignsAdd = React.lazy(() => import('./views/evcashCampaigns/evcashCampaignsEdit'))
const EvcashCampaignsList = React.lazy(() => import('./views/evcashCampaigns/evcashCampaignsList'))
const evcashReportsList = React.lazy(() => import('./views/evcashReports/evcashReportsList'))
const evcashWidgetsList = React.lazy(() => import('./views/evcashWidgets/evcashWidgetsList'))
const evcashThankYou = React.lazy(() => import('./views/evcashWidgets/evcashThankYou'))
const evcashEnvoicemanagement = React.lazy(() =>
  import('./views/ppcAdmin/evcashEnvoicemanagement/evcashEnvoicemanagement'),
)
const evcashPartnerSetting = React.lazy(() =>
  import('./views/ppcAdmin/partnerSetting/evcashPartnerSetting'),
)
const evcashWidgetSteps = React.lazy(() => import('./views/evcashWidgets/evcashWidgetSteps'))
const AccountManagement = React.lazy(() => import('./views/AccountManagement/AccountManagement'))
const WalletManagement = React.lazy(() => import('./views/WalletManagement/WalletManagement'))
const PaymentStatus = React.lazy(() => import('./views/AccountManagement/PaymentStatus'))
const TransactionHistory = React.lazy(() => import('./views/transactionHistory/transactionHistory'))
const EvcashOffersList = React.lazy(() => import('./views/evcashOffers/evcashOffersList'))
const Profile = React.lazy(() => import('./views/profile/profile'))
const Terms = React.lazy(() => import('./views/termsConditions/termsConditions'))
const FAQ = React.lazy(() => import('./views/faq/faq'))
const ContactUs = React.lazy(() => import('./views/contactUs/contactUs'))
const EvcashUsers = React.lazy(() => import('./views/evcashUsers/evcashUsers'))
const PerformanceReport = React.lazy(() => import('./views/reports/CampaignPerformanceReport'))
const evcashPlantationProjects = React.lazy(() =>
  import('./views/evcashPlantationProjects/evcashPlantationProjects'),
)
const PerformanceDetailReport = React.lazy(() =>
  import('./views/reports/CampaignPerformanceDetailReport'),
)
const NonConversionReport = React.lazy(() => import('./views/reports/NonConversionReport'))
const NonConversionDetailReport = React.lazy(() =>
  import('./views/reports/NonConversionDetailReport'),
)
const MerchantManagement = React.lazy(() =>
  import('./views/ppcAdmin/merchantManagement/merchantManagement'),
)
const MerchantApiManagement = React.lazy(() =>
  import('./views/ppcMerchant/merchantApiManagement/MerchantApiManagement'),
)

const PpcAdminReport = React.lazy(() => import('./views/ppcAdmin/ppcAdminReport/ppcAdminReport'))
const PpcMerchantInvoice = React.lazy(() =>
  import('./views/ppcMerchant/merchantInvoice/MerchantInvoice'),
)
const PpcMerchantInvoiceDetail = React.lazy(() =>
  import('./views/ppcMerchant/merchantInvoice/MerchantInvoiceDetail'),
)

const PpcPartnerTrees = React.lazy(() =>
  import('./views/ppcMerchant/ppcPartnerTrees/ppcPartnerTrees'),
)

const PpcAdminDashboard = React.lazy(() =>
  import('./views/ppcAdmin/ppcAdminDashboard/ppcAdminDashboard'),
)

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/brandSettings', name: 'Brand Settings', element: BrandSettings },
  { path: '/reports', name: 'Reports', element: Reports },
  { path: '/apiManagement', name: 'API Management', element: ApiManagement },
  { path: '/dashboard', name: 'Dashboard', element: EvcashCampaignsList },
  { path: '/widgets', name: 'My Widgets', element: evcashWidgetsList },
  { path: '/thankyou', name: 'thankyou', element: evcashThankYou },
  { path: '/trees', name: 'trees', element: PpcPartnerTrees },
  { path: '/treesBuy', name: 'Buy Trees', element: evcashPlantationProjects },
  { path: '/mastercardInvoiceManagement', name: 'Invoice', element: evcashEnvoicemanagement },
  { path: '/mastercardPartnerSetting', name: 'Partner', element: evcashPartnerSetting },
  { path: '/transaction-report', name: 'My Widgets', element: evcashReportsList },
  { path: '/widgetsSteps', name: 'My Widgets Steps', element: evcashWidgetSteps },
  { path: '/cropLogo', name: 'CropLogo', element: CropLogo },
  { path: '/evcashCampaignEdit', name: 'Campaign Edit', element: EvcashCampaignsEdit },
  { path: '/evcashCampaignAdd', name: 'Campaign Add', element: EvcashCampaignsAdd },
  { path: '/evcashCampaigns', name: 'Campaigns', element: EvcashCampaignsList },
  { path: '/AccountManagement', name: 'Account Management', element: AccountManagement },
  { path: '/WalletManagement', name: 'Wallet Management', element: WalletManagement },
  { path: '/PaymentStatus', name: 'Setup Complete', element: PaymentStatus },
  { path: '/transactionHistory', name: 'Transaction History', element: TransactionHistory },
  { path: '/evcashOffers', name: 'Offers', element: EvcashOffersList },
  { path: '/Profile', name: 'Profile', element: Profile },
  { path: '/TermsAndConditions', name: 'Terms and Conditions', element: Terms },
  { path: '/faq', name: 'FAQ', element: FAQ },
  { path: '/contactUs', name: 'Contact Us', element: ContactUs },
  { path: '/Users', name: 'Users', element: EvcashUsers },
  { path: '/performanceReport', name: 'Performance Report', element: PerformanceReport },
  {
    path: '/performanceReport/performanceReportDetailReport',
    name: 'Performance Detail Report',
    element: PerformanceDetailReport,
  },
  { path: '/nonConversionReport', name: 'Non-Conversion Report', element: NonConversionReport },
  {
    path: '/nonConversionReport/nonConversionReportDetailReport',
    name: 'Non-Conversion Detail Report',
    element: NonConversionDetailReport,
  },
  {
    path: '/merchantManagement',
    name: 'Partner Management',
    element: MerchantManagement,
  },
  { path: '/merchantApiManagement', name: 'API Management', element: MerchantApiManagement },
  { path: '/ppcAdminReport', name: 'PPC Admin Report', element: PpcAdminReport },
  { path: '/partnerInvoice', name: 'Monthly Invoices', element: PpcMerchantInvoice },
  { path: '/partnerInvoiceDetail', name: 'Monthly Invoices', element: PpcMerchantInvoiceDetail },
  { path: '/ppcAdminDashboard', name: 'Monthly Invoices', element: PpcAdminDashboard },
]

export default routes
