import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import OpenGraphMeta from './OpenGraphMeta'

const MetaWrapper = ({ children }) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const certificate = queryParams.get('certificate' || '')
  const brand = queryParams.get('brand' || '')
  const trees = queryParams.get('trees' || '')
  const meta = {
    brand: brand ? brand : '',
    baseUrlApi: window.location.host,
    certificate: certificate ? certificate : '',
    trees: trees ? trees : '',
  }

  return (
    <>
      <OpenGraphMeta {...meta} />
      {children}
    </>
  )
}

MetaWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MetaWrapper
