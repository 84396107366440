import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

const OpenGraphAndTwitterMeta = ({ brand, certificate, baseUrlApi, trees }) => {
  return (
    <Helmet>
      {console.log({ brand, certificate, baseUrlApi, trees }, baseUrlApi)}
      {/* Conditionally add meta tags based on the availability of parameters */}
      {trees && brand && (
        <>
          <meta name="twitter:title" content="I’ve Made a Difference!" />

          <meta name="twitter:description" content={`I have planted ${trees} with ${brand}`} />
          <meta name="twitter:image" content={`${baseUrlApi}/certificates/${certificate}.jpg`} />
          <meta property="og:image" content={`${baseUrlApi}/certificates/${certificate}.jpg`} />
          <meta name="twitter:description" content={`I have planted ${trees} with ${brand}`} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:title" content="Green Checkout" />
          <meta property="og:url" content="https://www.green-checkout.com" />
        </>
      )}
    </Helmet>
  )
}

OpenGraphAndTwitterMeta.propTypes = {
  brand: PropTypes.string,
  certificate: PropTypes.string,
  trees: PropTypes.number,
  baseUrlApi: PropTypes.number,
}

export default OpenGraphAndTwitterMeta
